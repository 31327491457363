import "./src/styles/global.css";
import "@fontsource/poppins";
import "@fontsource/poppins/600.css";

import React from "react";
import { AuthProvider } from "./src/context/AuthProvider";
import PersistLogin from "./src/components/PersistLogin";


export const wrapRootElement = ({ element }) => (
	<AuthProvider>
		<PersistLogin>
			{element}
		</PersistLogin>
	</AuthProvider>
);