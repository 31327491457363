import axios from "../api/axios";
import useAuth from "./useAuth";

const REFRESH_URL = "/wp-json/api-bearer-auth/v1/tokens/refresh";


/**
 *
 *  Retorna función que actualiza el access token almacenado en el contexto,
 *  usando el refresh token de la http only cookie si existe
 */
const useRefreshToken = () => {
	const { setAuth } = useAuth();

	const refreshToken = async () => {

		const response = await axios.post(REFRESH_URL, {},
			{
				withCredentials: true
			}
		);

		setAuth(prev => {
			console.log(JSON.stringify(prev));
			console.log(response.data.access_token);
			return { ...prev, user: response.data.user_name, accessToken: response.data.access_token };
		});
		return response.data.access_token;
	};
	return refreshToken;
};

export default useRefreshToken;